<template>
  <div>
    <van-popup
      v-model="show"
      position="bottom"
      :style="{ height: '90%' }"
      close-icon="cross"
      :close-on-click-overlay="false"
      round
      @click-overlay="onClose"
    >
      <div class="titleBar">
        <div class="searchBar">
          <van-search
            v-model="filters.name"
            placeholder="请输入影片名关键词"
            @search="onSearch"
            input-align="center"
          />
        </div>
        <div class="filterBar">
          <van-dropdown-menu active-color="#1989fa">
            <van-dropdown-item
              v-model="filters.type"
              :options="typeList"
              @change="getFilmList(true)"
            />
            <van-dropdown-item
              v-model="filters.year"
              :options="yearList"
              @change="getFilmList(true)"
            />
            <van-dropdown-item
              v-model="filters.project"
              :options="projectList"
              @change="getFilmList(true)"
            />
            <van-dropdown-item
              v-model="filters.sort"
              :options="sortList"
              @change="getFilmList(true)"
            />
          </van-dropdown-menu>
        </div>
      </div>
      <div class="content">
        <div class="filmList">
          <van-list
            :loading="showLoading"
            class="van-clearfix"
            :finished="finished"
            @load="nextFilms"
          >
            <van-row
              class="filmItem float-item"
              v-for="(movie, index) in filmList"
              :key="index"
            >
              <movieCard
                :movie-img="movie.img"
                :movie-title="movie.title"
                :movie-score="movie.score"
                :movie-play-num="movie.playNum"
                :movie-type="movie.type"
                :movie-id="movie.id"
                :movie-length="movie.length"
                :movie-description="movie.description"
                :onDemand="movie.onDemand"
                :movie-year="movie.year"
                @select="handleMovie(movie)"
              ></movieCard>
            </van-row>
            <van-row v-if="filmList.length < 1">
              <van-empty description="暂无影片可选" />
            </van-row>
          </van-list>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import movieCard from '@comp/movieCard'
import { Dialog, Toast } from 'vant'
import { api } from '@/api/index'

export default {
  components: {
    movieCard,
  },
  name: 'index',

  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    // 放映点id
    showPointId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showLoading: false,
      finished: false,
      pages: 1,
      pageNo: 1,
      filters: {
        name: '',
        type: '',
        year: '',
        project: '',
        sort: 0,
      },
      typeList: [
        { text: '类型', value: '' },
        // 故事片
        { text: '故事片', value: '故事片' },
        // 科教片
        { text: '科教片', value: '科教片' },
        // 戏曲片
        { text: '戏曲片', value: '戏曲片' },
        // 纪录片
        { text: '纪录片', value: '纪录片' },
        // 动画片
        { text: '动画片', value: '动画片' },
        // 美术片
        { text: '美术片', value: '美术片' },
        // 其它
        { text: '其它', value: '其它' },
      ],
      yearList: [],
      // 专题列表
      projectList: [
        {
          text: '专题',
          value: '',
        },
      ],
      // 默认排序
      sortList: [
        {
          text: '排序',
          value: 0,
        },
        {
          text: '最热',
          value: 1,
        },
        {
          text: '播放',
          value: 2,
        },
        {
          text: '评分',
          value: 3,
        },
        {
          text: '最新',
          value: 4,
        },
      ],
      filmList: [],
    }
  },
  mounted() {
    // 动态生成最近10年
    const year = new Date().getFullYear()
    const Arr = []
    Arr.push({ text: '年份', value: '' })
    Array.from({ length: 15 }, (v, k) => year - k).forEach((item) => {
      Arr.push({ text: item, value: item })
    })
    Arr.push({ text: '更早', value: '0' })
    this.yearList = Arr
    this.initPage()
  },
  methods: {
    initPage() {
      this.filmList = []
      this.pageNo = 1
      this.finished = false
      this.showLoading = true
      this.getThemeDate()
      this.getFilmList()
    },
    //获取专题列表
    getThemeDate() {
      if (!this.showPointId){
        return
      }
      api
        .getShowPointThemeList({
          showPointId: this.showPointId,
        })
        .then((res) => {
          console.log({ res })
          this.projectList = [
            {
              text: '专题',
              value: '',
            },
          ]
          res.map((item) => {
            if (item.status === 1) {
              this.projectList.push({
                text: item.themeName,
                value: item.themeName,
              })
            }
          })
        })
    },
    // 获取影片列表
    async getFilmList(reload = false) {
      if(!this.showPointId){
        Toast.fail('请选择放映点')
        return
      }
      if (reload) {
        this.filmList = []
        this.pageNo = 1
        this.finished = false
      }
      await api
        .getFilmList({
          showPointId: this.showPointId,
          type: this.filters.type,
          name: this.filters.name,
          sort: this.filters.sort,
          themeName: this.filters.project,
          year: this.filters.year,
          pageNo: this.pageNo,
          pageSize: 10,
        })
        .then((res) => {
          const { records } = res
          records.map((item) => {
            this.filmList.push({
              id: item.id,
              title: item.name,
              score: item.score,
              playNum: item.clickCount,
              type: item.type,
              img: item.filmPicture,
              length: item.filmLength,
              description: item.synopsis,
              year: item.year.toString(),
              // onDemand: true
            })
          })
          this.showLoading = false
          if (records.length === 0) {
            this.finished = true
          }
        })
    },
    // 获取下一页影片详情
    nextFilms() {
      console.log('下一页')
      this.loading = true
      this.pageNo += 1
      console.log(this.pageNo)
      this.getFilmList()
    },
    handleMovie(item) {
      Dialog.confirm({
        title: '提示',
        message: `确认添加《${item.title}》到点播电影列表吗？`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$emit('onFinished',{...item})
      })

      console.log(item)
    },
    onClose() {
      this.$emit('close')
    },
    onSearch() {
      this.getFilmList(true)
      // console.log('search', this.value);
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.initPage()
      }
    },
  }
}
</script>

<style scoped lang="less">
.content {
  padding: 10px;
}
.titleBar {
  position: fixed;
  width: 100%;
  z-index: 10;
  border-radius: 20px 0 0 20px;
}
.content {
  margin-top: 12vh;
}
.searchBar {
  //border: 1px solid red;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.filterBar {
}
</style>
