<template>
  <div>
    <!--    修改任务弹出框-->
    <van-popup
        v-model="showModify"
        closeable
        position="bottom"
        :style="{ height: '100%' }"
    >
      <div class="modifyPlan">
        <div>
          <h4><span class="star">*</span>修改任务</h4>
        </div>
        <div>
          <van-field
              v-model="tempPlanData.playDate"
              @click="showDateCalendar = true"
              autosize
              required
              readonly
              label="放映日期"
              type="text"
              right-icon="notes-o"
          />
          <van-field
              v-model="tempPlanData.playTime"
              @click="showTimeCalendar = true"
              autosize
              required
              readonly
              label="放映时间"
              type="text"
              right-icon="clock-o"
          />
          <van-field
              v-model="tempPlanData.showPoint.address"
              @click="$refs.selectPoint.open()"
              autosize
              label="放映地点"
              type="textarea"
              right-icon="location-o"
          >
            <template #input>
              {{ tempPlanData.showPoint.name }} ({{
                tempPlanData.showPoint.address
              }})
            </template>
          </van-field>
          <!-- 放映员信息 -->
          <van-field
              v-model="tempPlanData.screenwriter"
              label="放映员"
              type="text"
              right-icon="user-o"
          >
            <template #input>
              {{ tempPlanData.filmteamName }}
            </template>
          </van-field>
          <van-cell title="点播影片" required>
            <template #label>
              <div class="filmBtn">
                <van-button
                    icon="tv-o"
                    type="info"
                    round
                    size="small"
                    @click="showFilmPicker = true"
                >选择电影
                </van-button>
                <span
                >已选中 <i class="count">{{ tempPlanData.films.length }}</i
                >部</span
                >
              </div>
            </template>
          </van-cell>
          <van-cell v-for="(film, index) in tempPlanData.films" :key="index">
            <template #default>
              <div class="film">
                <div class="film-mark">{{ index + 1 }}</div>
                <div class="film-title">{{ film.name }}</div>
                <div class="film-del">
                  <van-icon
                      name="delete"
                      @click="removeFilm(index)"
                      size="20"
                  />
                  <van-icon
                      name="upgrade"
                      color="#3d8bff"
                      size="20"
                      v-if="index !== 0 && tempPlanData.films.length !== 1"
                      @click="moveUpFilm(index)"
                  />
                  <van-icon
                      name="upgrade"
                      color="#3d8bff"
                      style="transform: rotate(180deg)"
                      size="20"
                      v-if="index !== tempPlanData.films.length - 1"
                      @click="moveDownFilm(index)"
                  />
                </div>
              </div>
            </template>
          </van-cell>
          <van-field
              v-model="reason"
              required
              label="修改原因"
              rows="2"
              autosize
              type="textarea"
              maxlength="150"
              placeholder="请描述原因"
              show-word-limit
          />
        </div>
        <div>
          <!--          {{tempPlanData}}-->
        </div>
        <div>
          <!--          提交修改-->
          <van-button
              class="btn"
              type="info"
              size="small"
              block
              @click="savePlan"
          >
            确认
          </van-button>
        </div>
      </div>
      <!-- <pre>
      {{ tempPlanData }}
    </pre -->
    </van-popup>

    <!-- 选择影片弹出框 -->
    <FilmPicker
        :show="showFilmPicker"
        v-if="showFilmPicker"
        :showPointId="tempPlanData.showPoint.id"
        @close="showFilmPicker = false"
        @onFinished="selectFilm"
    ></FilmPicker>

    <!-- 选择影院弹出框 -->
    <van-calendar
        v-model="showDateCalendar"
        :first-day-of-week="1"
        color="#1989fa"
        @close="showDateCalendar = false"
        @confirm="onConfirmDate"
    ></van-calendar>
    <!-- 选择放映时间弹出框 -->
    <van-popup
        v-model="showTimeCalendar"
        position="bottom"
        :style="{ height: '40%' }"
    >
      <van-datetime-picker
          type="time"
          v-model="tempPlanData.playTime"
          @close="showTimeCalendar = false"
          @confirm="onConfirmTime"
      ></van-datetime-picker>
    </van-popup>
  </div>
</template>
<script>
import FilmPicker from '@/components/filmPicker/index'
import {Dialog, Toast} from 'vant'
import {api} from '@/api'
import moment from "moment";

export default {
  name: 'EditPlan',
  components: {
    FilmPicker,
  },
  data() {
    return {
      showModify: false,
      modifyMessage: '',
      showDateCalendar: false,
      showTimeCalendar: false,
      showAreaPicker: false,
      showFilmPicker: false,
      showFilmList: false,
      reason: '',
      tempPlanData: {
        showPoint: {
          address: '',
        },
        films: [],
      },
    }
  },
  methods: {
    show(plan) {
      console.log({plan})
      this.modifyPlan(plan)
    },
    // 修改任务
    modifyPlan(plan) {
      this.showModify = true
      // 深拷贝
      this.tempPlanData = JSON.parse(JSON.stringify(plan))
      this.tempPlanData.reason = ''

      this.tempPlanData.playDate = this.tempPlanData.playDatetime.split(' ')[0]
      this.tempPlanData.playTime = this.tempPlanData.playDatetime.split(' ')[1]
      this.tempPlanData.movieList = this.tempPlanData.films.map(
          (item, index) => {
            return {
              filmId: item.id,
              filmName: item.name,
              sort: index,
            }
          },
      )
      console.log(this.tempPlanData)
      this.modifyMessage = ''
    },

    // 选择影片
    selectFilm(film) {
      console.log(film)
      if (this.tempPlanData.films.some((item) => item.id === film.id)) {
        Toast('该影片已添加')
        return
      }
      this.tempPlanData.films.push({
        id: film.id,
        name: film.title,
      })
      Toast('已添加影片')
    },
    // 删除影片
    removeFilm(index) {
      this.tempPlanData.films.splice(index, 1)
    },
    // 上移影片
    moveUpFilm(index) {
      if (index === 0) return
      let filmList = this.tempPlanData.films
      let temp = filmList[index]
      filmList[index] = filmList[index - 1]
      filmList[index - 1] = temp
      this.tempPlanData.films = [...filmList]
    },
    // 下移影片
    moveDownFilm(index) {
      if (index === this.tempPlanData.films.length - 1) return
      let filmList = this.tempPlanData.films
      let temp = filmList[index]
      filmList[index] = filmList[index + 1]
      filmList[index + 1] = temp
      this.tempPlanData.films = [...filmList]
    },
    // 修改缓存日期
    onConfirmDate(date) {
      function formatDate(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }

      this.tempPlanData.playDate = formatDate(date)
      this.tempPlanData.playDatetime = `${this.tempPlanData.playDate} ${this.tempPlanData.playTime}`
      // console.log(this.tempPlanData.playDate);
      this.showDateCalendar = false
    },
    // 修改缓存时间
    onConfirmTime(time) {
      this.tempPlanData.playTime = time + ':00'
      this.tempPlanData.playDatetime = `${this.tempPlanData.playDate} ${this.tempPlanData.playTime}`
      // console.log(this.tempPlanData.playTime);
      this.showTimeCalendar = false
    },
    onConfirmFilmTeam(filmTeam) {
      console.log("AAAAAAAAAAAAA", filmTeam);
      this.tempPlanData = {
        ...this.tempPlanData,
        filmteamName: filmTeam.name,
        filmteamId: filmTeam.id,
      }
    },
    // 保存修改任务
    savePlan() {
      if (this.tempPlanData.films.length === 0) {
        Toast('请选择影片')
        return
      }
      if (this.reason.length < 5) {
        Toast('请选择填写备注，不少于5个字')
        return
      }

      const playDateTime= `${this.tempPlanData.playDate} ${this.tempPlanData.playTime}`
      const postData = {
        id: this.tempPlanData.id,
        filmteamId: this.tempPlanData.filmteamId,
        filmIds: this.tempPlanData.films.map((item) => item.id),
        playDatetime: moment(playDateTime).format('YYYY-MM-DD HH:mm:ss'),
        // projectionistEditable: false,
        reason: this.reason,
      }
      // 是否提交修改任务
      Dialog.confirm({
        title: '修改任务',
        message: '确定修改该任务吗？',
      }).then(() => {
        api.filmmaker.updatePlan(postData).then((res) => {
          console.log(res);
          const {success, message} = res
          if (!success) {
            Toast(message)
            return
          } else {
            Toast('修改成功')
            this.showModify = false
            this.$emit('success')
          }

        })
      })
    },
  },
}
</script>
<style scoped lang="less">
.modifyPlan {
  padding: 10px;
}

.filmBtn {
  width: 45vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .count {
    color: @color-primary;
    font-style: normal;
    font-weight: bold;
    padding: 0 5px;
  }
}

.film {
  display: flex;
  align-items: center;

  &-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @color-primary;
    margin: 12px;
    position: relative;

    &:before {
      content: '';
      border: 1px solid @color-primary;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
    }
  }

  &-title {
    padding: 12px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e8e9ec;
    width: 70vw;
    color: #686b73;
  }

  &-del {
    color: @color-error;
    margin-left: 12px;
    width: 20vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
